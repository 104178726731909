import { window } from "global";
import { useLocation } from "react-router-dom";
import { useGlobal } from "reactn";
import { RiotAssetsProvider } from "@outplayed/riot-assets";
import { ResponsiveProvider, MediaQuery } from "@outplayed/responsive";

import ApolloWrapper from "components/ApolloWrapper";
import MobileMenu from "components/MobileMenu/MobileMenu";
import Masthead from "components/Masthead/Masthead";
import MainContent from "components/MainContent/MainContent";
import Footer from "components/Footer/Footer";
import OpenSearch from "components/OpenSearch";
import { Test } from "./Test";

import { DEFAULT_BREAKPOINT, BREAKPOINTS } from "constants.js";
import { RampContainer } from "./RampContainer";

const AdPadding = () => {
  const [detectedAdBlock] = useGlobal("detectedAdBlock");

  return <div style={{ paddingBottom: detectedAdBlock ? 0 : 100 }}></div>;
};

const App = (props) => {
  const { client } = props;
  const location = useLocation();

  if (location.pathname.includes("/opensearch")) {
    return (
      <RiotAssetsProvider staging={process.env.RIOT_PATCH_ASSETS === "staging"} ssr>
        <OpenSearch />
      </RiotAssetsProvider>
    );
  }

  return (
    <>
      {window && <RampContainer />}
      <ApolloWrapper client={client}>
        <RiotAssetsProvider staging={process.env.RIOT_PATCH_ASSETS === "staging"} ssr>
          <ResponsiveProvider breakpoints={BREAKPOINTS} defaultBreakpoint={DEFAULT_BREAKPOINT}>
            <MediaQuery min="MOBILE_SMALL" max="MOBILE_LARGE" isClient renderNullOnFail>
              <MobileMenu />
            </MediaQuery>
            <Masthead />
            <MainContent />
            <Footer />
            <Test />
            <AdPadding />
          </ResponsiveProvider>
        </RiotAssetsProvider>
      </ApolloWrapper>
    </>
  );
};

export default App;
