import React, { useState, useEffect, useMemo } from "react";
import classnames from "classnames";
import FilterSelect from "../FilterSelect";

const OtpFilter = (props) => {
  const { className, value, options, onFilterChange, isMobile } = props;
  const [isActive, setActive] = useState(value);
  const backgroundStyleActive = {
    backgroundImage: `linear-gradient(270deg, #454566 0%, #6f719f 100%)`,
    backgroundRepeat: "no-repeat",
    //backgroundSize: "20% 100%"
  };
  const backgroundStyle = {
    backgroundColor: "#1d1d39",
  };
  function onToggleClick() {
    if (isActive) {
      setActive(false);
      onFilterChange("isOtp", options[0]);
    } else {
      setActive(true);
      onFilterChange("isOtp", options[1]);
    }
  }
  return (
    <div
      className="filter-otp filter-select-wrapper"
      onClick={() => onToggleClick()}
      style={isActive ? backgroundStyleActive : backgroundStyle}
    >
      {/* <img className="world-logo"  src={require("images/worlds-logo.png").default} alt="world-logo"/>*/}
      <span> OTP </span>
    </div>
  );
};

export default OtpFilter;
