import { window } from "global";
import React, { useState, useEffect, useRef, useMemo } from "react";
import mobile from "is-mobile";
import { useGlobal } from "reactn";
import classnames from "classnames";
import { Helmet } from "react-helmet-async";
import { MediaQuery } from "@outplayed/responsive";
import { getLeagueSeriesUrl, getStaticPlayer2Url, getTeamUrl } from "@outplayed/riot-assets";

import Checkbox from "components/common/Checkbox/Checkbox";
import ProHeadshot from "components/common/ProHeadshot";
import Image from "components/common/Image";
import { useProList } from "lib/hooks/json-hooks";
import { ReactComponent as TrashIcon } from "svg/trash-icon.svg";
import { ReactComponent as Checkmark } from "svg/checkmark.svg";
import { ReactComponent as SearchIcon } from "svg/search-icon.svg";
import { amplitudeLog } from "lib/general-helpers";
import { normalizeLeagueUrl } from "lib/general-helpers";

export const TRASH_PLAYER_KEY = "player";
export const TRASH_TEAM_KEY = "team";
export const TRASH_LEAGUE_KEY = "league";

function normalizeSearchValue(value) {
  return value.trim().replace(/ /g, "").toLowerCase();
}

function normalizedIndexOf(string1, string2) {
  return normalizeSearchValue(string1).indexOf(normalizeSearchValue(string2));
}

function intersection(setA, setB) {
  let _intersection = new Set();
  for (let elem of setB) {
    if (setA.has(elem)) {
      _intersection.add(elem);
    }
  }
  return _intersection;
}

function getSet(arr, key) {
  const set = new Set();
  for (const el of arr) {
    set.add(el[key]);
  }
  return set;
}

function getEditList(type, typeKey, list) {
  const editList = {};
  for (const item of list) {
    editList[`${type}_${item[typeKey]}`] = { type, value: item[typeKey], checked: item.checked, data: item };
  }
  return editList;
}

const ListItem = (props) => {
  const observerNode = useRef();
  const observer = useRef();
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (setShow && observer.current) {
      return observer.current.disconnect();
    }

    observer.current = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setShow(true);
        }
      },
      { threshold: 0 },
    );

    if (observerNode.current) {
      observer.current.observe(observerNode.current);
    }

    return () => observer.current.disconnect();
  }, [setShow]);

  if (!show) {
    return <li ref={observerNode}></li>;
  }

  return props.children;
};

const TrashBucket = (props) => {
  const [mounted, setMounted] = useState(false);
  const [saved, setSaved] = useState(false);
  const [editList, setEditList] = useState(getEditList(props.type, props.typeKey, props.list));

  const { type, typeKey, backgroundColor, title, labelComponent, list, onUpdate } = props;

  useEffect(() => {
    setEditList(getEditList(type, typeKey, list));
  }, [list]);

  const onChange = (el, checked) => {
    const updatedList = { ...editList };
    updatedList[`${type}_${el[typeKey]}`]["checked"] = checked;
    setEditList(updatedList);
  };

  const onUpdateBucket = () => {
    setSaved(true);
    onUpdate(Object.values(editList), type);
    amplitudeLog("save-trash", { "trash-type": type });
  };

  return (
    <div className="bucket">
      {title && <div className="bucket_title">{title}</div>}
      <div className="bucket_content" style={{ backgroundColor }}>
        <ul style={{ paddingRight: list.length > 7 ? 12 : 0 }}>
          {list.map((el) => (
            <ListItem key={el[typeKey]}>
              <li>
                <Checkbox
                  onChange={(e) => onChange(el, e.target.checked)}
                  checked={editList[`${type}_${el[typeKey]}`] && editList[`${type}_${el[typeKey]}`]["checked"]}
                >
                  <div className="label" style={{ width: list.length > 7 ? "calc(100% - 28px)" : "calc(100% - 16px)" }}>
                    {labelComponent(el)}
                  </div>
                </Checkbox>
              </li>
            </ListItem>
          ))}
        </ul>
        <div
          className={classnames("update-btn", { "save-state": saved })}
          onClick={onUpdateBucket}
          onMouseEnter={() => setSaved(false)}
        >
          {saved ? (
            <React.Fragment>
              <Checkmark /> Saved
            </React.Fragment>
          ) : (
            <React.Fragment>
              <TrashIcon /> Update
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  );
};

const TrashcanPage = (props) => {
  // window && localStorage.removeItem("pbs_trash")
  const [searchInput, setSearchInput] = useState("");
  const [localTrash, setLocalTrash] = useGlobal("localTrash");
  const { data, loading, error } = useProList();

  const uniqueProList = useMemo(() => {
    if (!data) return [];
    return Object.values(data);
  }, [data]);

  const formattedData = useMemo(() => {
    if (!data) return null;

    return uniqueProList.reduce(
      (acc, curr) => {
        const { normalized_name, current_team, league } = curr;
        if (!acc[TRASH_LEAGUE_KEY][league]) {
          acc[TRASH_LEAGUE_KEY][league] = {
            [TRASH_TEAM_KEY]: {
              [current_team]: {
                [TRASH_PLAYER_KEY]: {
                  [normalized_name]: { ...curr },
                },
              },
            },
          };
        } else if (!acc[TRASH_LEAGUE_KEY][league][TRASH_TEAM_KEY][current_team]) {
          acc[TRASH_LEAGUE_KEY][league][TRASH_TEAM_KEY][current_team] = {
            [TRASH_PLAYER_KEY]: {
              [normalized_name]: { ...curr },
            },
          };
        } else {
          acc[TRASH_LEAGUE_KEY][league][TRASH_TEAM_KEY][current_team][TRASH_PLAYER_KEY][normalized_name] = { ...curr };
        }
        return acc;
      },
      { [TRASH_LEAGUE_KEY]: {} },
    );
  }, [uniqueProList, localTrash]);

  const playerSet = useMemo(() => getSet(uniqueProList, "normalized_name"), [uniqueProList]);
  const teamSet = useMemo(() => getSet(uniqueProList, "current_team"), [uniqueProList]);
  const leagueSet = useMemo(() => getSet(uniqueProList, "league"), [uniqueProList]);

  const onSearchChange = (e) => {
    setSearchInput(e.target.value);
  };

  const onUpdate = (updatedList, bucketType) => {
    const newTrash = localTrash ? new Set([...localTrash]) : new Set();

    // First apply all changes before doing additional checks
    for (const { type, value, checked, data } of updatedList) {
      const trashItem = `${type}_${value}`;
      checked ? newTrash.add(trashItem) : newTrash.delete(trashItem);
    }

    // Clean up cookies (Remove redundancies)
    const tempData = JSON.parse(JSON.stringify(formattedData));
    for (const [league, leagueData] of Object.entries(tempData[TRASH_LEAGUE_KEY])) {
      const leagueTrashItem = `${TRASH_LEAGUE_KEY}_${league}`;
      // Flag to check if all league teams or players are checked
      let allLeagueTeamsChecked = true;
      let allLeaguePlayersChecked = true;
      if (bucketType === TRASH_LEAGUE_KEY && !newTrash.has(leagueTrashItem)) {
        newTrash.delete(leagueTrashItem);
      }

      for (const [team, teamData] of Object.entries(leagueData[TRASH_TEAM_KEY])) {
        const teamTrashItem = `${TRASH_TEAM_KEY}_${team}`;
        // Flag to check if all team players are checked
        let allTeamPlayersChecked = true;
        if (
          (bucketType === TRASH_TEAM_KEY && !newTrash.has(teamTrashItem)) ||
          (bucketType === TRASH_LEAGUE_KEY && !newTrash.has(leagueTrashItem))
        ) {
          allLeagueTeamsChecked = false;
          newTrash.delete(teamTrashItem);
        }

        for (const [player, playerData] of Object.entries(teamData[TRASH_PLAYER_KEY])) {
          const playerTrashItem = `${TRASH_PLAYER_KEY}_${player}`;
          if (bucketType === TRASH_PLAYER_KEY && (newTrash.has(teamTrashItem) || newTrash.has(leagueTrashItem))) {
            newTrash.delete(playerTrashItem);
          }
          if (
            (bucketType === TRASH_PLAYER_KEY && !newTrash.has(playerTrashItem)) ||
            (bucketType === TRASH_TEAM_KEY && !newTrash.has(teamTrashItem)) ||
            (bucketType === TRASH_LEAGUE_KEY && !newTrash.has(leagueTrashItem))
          ) {
            allTeamPlayersChecked = false;
            allLeaguePlayersChecked = false;
            newTrash.delete(playerTrashItem);
            newTrash.delete(teamTrashItem);
          }
        }
        if (bucketType !== TRASH_TEAM_KEY && bucketType !== TRASH_LEAGUE_KEY && allTeamPlayersChecked) {
          newTrash.add(teamTrashItem);
        }
      }
      if (!allLeagueTeamsChecked || !allLeaguePlayersChecked) {
        newTrash.delete(leagueTrashItem);
      } else if (bucketType !== TRASH_LEAGUE_KEY) {
        newTrash.add(leagueTrashItem);
      }
    }

    if (newTrash.size === 0) {
      window && localStorage.removeItem("pbs_trash");
      setLocalTrash();
    } else {
      window && localStorage.setItem("pbs_trash", JSON.stringify([...newTrash]));
      setLocalTrash(newTrash);
    }
  };

  const getLists = () => {
    if (!data)
      return {
        players: [],
        teams: [],
        leagues: [],
      };

    let playersChecked = new Set();
    let playersUnchecked = new Set(playerSet);
    let teamsChecked = new Set();
    let teamsUnchecked = new Set(teamSet);
    let leaguesChecked = new Set();
    let leaguesUnchecked = new Set(leagueSet);

    if (localTrash) {
      for (const player of uniqueProList) {
        const { normalized_name, current_team, league } = player;
        for (const trashItem of localTrash) {
          const [type, value] = trashItem.split(/_(.+)/);
          if (type === TRASH_PLAYER_KEY && normalized_name === value) {
            playersChecked.add(normalized_name);
            playersUnchecked.delete(normalized_name);
          } else if (type === TRASH_TEAM_KEY && current_team === value) {
            playersChecked.add(normalized_name);
            playersUnchecked.delete(normalized_name);
            teamsChecked.add(current_team);
            teamsUnchecked.delete(current_team);
          } else if (type === TRASH_LEAGUE_KEY && league === value) {
            playersChecked.add(normalized_name);
            playersUnchecked.delete(normalized_name);
            teamsChecked.add(current_team);
            teamsUnchecked.delete(current_team);
            leaguesChecked.add(league);
            leaguesUnchecked.delete(league);
          }
        }
      }
    }

    if (searchInput) {
      let searchPlayers = new Set();
      let searchTeams = new Set();
      let searchLeagues = new Set();
      for (const player of uniqueProList) {
        if (
          normalizedIndexOf(player.normalized_name, searchInput) === 0 ||
          normalizedIndexOf(player.official_name, searchInput) === 0 ||
          normalizedIndexOf(player.current_team, searchInput) === 0 ||
          normalizedIndexOf(player.league, searchInput) === 0
        ) {
          searchPlayers.add(player.normalized_name);
          searchTeams.add(player.current_team);
          searchLeagues.add(player.league);
        }
      }
      playersChecked = intersection(playersChecked, searchPlayers);
      playersUnchecked = intersection(playersUnchecked, searchPlayers);
      teamsChecked = intersection(teamsChecked, searchTeams);
      teamsUnchecked = intersection(teamsUnchecked, searchTeams);
      leaguesChecked = intersection(leaguesChecked, searchLeagues);
      leaguesUnchecked = intersection(leaguesUnchecked, searchLeagues);
    }

    function organizeList(arr, key, sortKey, isChecked) {
      return arr
        .map((el) => ({ ...(uniqueProList.find((pro) => pro[key] === el) || {}), checked: !!isChecked }))
        .sort((a, b) => a[sortKey].localeCompare(b[sortKey]));
    }
    playersChecked = organizeList([...playersChecked], "normalized_name", "official_name", true);
    playersUnchecked = organizeList([...playersUnchecked], "normalized_name", "official_name");
    teamsChecked = organizeList([...teamsChecked], "current_team", "current_team", true);
    teamsUnchecked = organizeList([...teamsUnchecked], "current_team", "current_team");
    leaguesChecked = organizeList([...leaguesChecked], "league", "league", true);
    leaguesUnchecked = organizeList([...leaguesUnchecked], "league", "league");

    return {
      players: [...playersChecked, ...playersUnchecked],
      teams: [...teamsChecked, ...teamsUnchecked],
      leagues: [...leaguesChecked, ...leaguesUnchecked],
    };
  };

  const { players, teams, leagues } = useMemo(() => getLists(), [data, searchInput, localTrash]);
  const playerItem = (data) => {
    return (
      <div className="trash-item">
        <div className="trash-item_image-container">
          <ProHeadshot className="player-image" src={getStaticPlayer2Url(data.normalized_name)} />
        </div>
        <div className="player-info">
          <div className="trash-item_label">{data.official_name}</div>
          <div className="flex align-center">
            <Image className="player-info_team-image" src={getTeamUrl(data.current_team)} />
            <div className="player-info_team-name">{data.current_team}</div>
          </div>
        </div>
      </div>
    );
  };
  const teamItem = (data) => {
    return (
      <div className="trash-item">
        <div className="trash-item_image-container">
          <Image className="team-image" src={getTeamUrl(data.current_team)} />
        </div>
        <div className="trash-item_label">{data.current_team}</div>
      </div>
    );
  };
  const leagueItem = (data) => {
    return (
      <div className="trash-item">
        <div className="trash-item_image-container">
          <img className="league-image" src={getLeagueSeriesUrl(normalizeLeagueUrl(data.league))} />
        </div>
        <div className="trash-item_label">{data.league}</div>
      </div>
    );
  };

  return (
    <div className="trashcan-page">
      <Helmet>
        <title>Trash pros: everyone here is trash</title>
        <meta
          name="description"
          content="Trashed items (Pros, Teams, or Leagues) will not appear on Champion page results. Ex: trashing the “LCS” removes all LCS players from Champion Probuilds pages, such as Ezreal Probuilds. If searched, you will still be able to view the LCS Team page."
        />
        <meta property="og:image" content="https://static.bigbrain.gg/assets/probuildstats/logos/pbs-logo.svg" />
      </Helmet>
      <h5>Everyone here is trash.</h5>
      <div className="para">
        Trashed items (Pros, Teams, or Leagues) will not appear on Champion page results. Ex: trashing the “LCS” removes all LCS
        players from Champion Probuilds pages, such as Ezreal Probuilds. If searched, you will still be able to view the LCS Team
        page.
      </div>
      <div className="para">
        <div>
          Check an item and click <span style={{ color: "#ffffff" }}>"Update"</span> to add them to your Trashcan.
        </div>
        <div>
          To remove an item from your trashcan, simply remove the <Checkbox checked={true} inactive readOnly /> next to their name
          below and click <span style={{ color: "#ffffff" }}>"Update"</span>.
        </div>
      </div>
      {/*<button onClick={() => {localStorage.removeItem("pbs_trash"); setLocalTrash();}}>Clear</button>*/}
      <div className="trash-search">
        <SearchIcon className="search-icon" />
        <input placeholder="Search for a Pro Player, Team, or League" value={searchInput} onChange={onSearchChange} />
      </div>
      <div className="trash-buckets">
        <TrashBucket
          type={TRASH_PLAYER_KEY}
          typeKey={"normalized_name"}
          backgroundColor={"#1d1d39"}
          title={"Pros"}
          list={players}
          labelComponent={playerItem}
          onUpdate={onUpdate}
        />
        <TrashBucket
          type={TRASH_TEAM_KEY}
          typeKey={"current_team"}
          backgroundColor={"#111129"}
          title={"Teams"}
          list={teams}
          labelComponent={teamItem}
          onUpdate={onUpdate}
        />
        <TrashBucket
          type={TRASH_LEAGUE_KEY}
          typeKey={"league"}
          backgroundColor={"#1d1d39"}
          title={"Leagues"}
          list={leagues}
          labelComponent={leagueItem}
          onUpdate={onUpdate}
        />
      </div>
    </div>
  );
};

const TrashcanPageContainer = () => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  if (!mounted) {
    return null;
  }

  const errorPage = (
    <div className="trashcan-page trashcan-page_error">
      <div className="message">
        <img src="https://static.bigbrain.gg/assets/probuildstats/icons/error-outline.png" />
        <div>
          <h1>This page is currently not available.</h1>
          <span>Please revisit this page on desktop for access.</span>
        </div>
      </div>
    </div>
  );

  if (mobile()) {
    return errorPage;
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Settings - Trashcan</title>
      </Helmet>
      <MediaQuery min="MOBILE_SMALL" max="MOBILE_LARGE" renderNullOnFail isClient>
        {errorPage}
      </MediaQuery>
      <MediaQuery min="TABLET" max="DESKTOP_LARGE" renderNullOnFail isClient>
        <TrashcanPage />
      </MediaQuery>
    </React.Fragment>
  );
};

export default TrashcanPageContainer;
