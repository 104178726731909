import { window } from "global";
const amplitude = window && require("amplitude-js");

export async function checkWebpSupport() {
  if (!self.createImageBitmap) return false;

  const webpData = "data:image/webp;base64,UklGRh4AAABXRUJQVlA4TBEAAAAvAAAAAAfQ//73v/+BiOh/AAA=";
  const blob = await fetch(webpData).then((r) => r.blob());
  return createImageBitmap(blob).then(
    () => true,
    () => false,
  );
}

export function amplitudeLog(eventId, options) {
  if (amplitude) {
    amplitude.logEvent(eventId, options);
  }
}

export function parseQueryString(query) {
  if (!query) return {};

  let formattedQuery = query;
  if (query.charAt(0) === "?") {
    formattedQuery = query.substring(1);
  }

  const decodeQuery = decodeURI(formattedQuery);
  const keys_values = decodeQuery.split("&");
  let queryObj = {};

  for (let param of keys_values) {
    let splitKeyValue = param.split("=");
    if (splitKeyValue[0] && splitKeyValue[1] !== undefined) {
      queryObj[splitKeyValue[0]] = splitKeyValue[1];
    }
  }

  return queryObj;
}

export function buildQueryParams(params) {
  return Object.keys(params)
    .map((key) => {
      return encodeURIComponent(key) + "=" + encodeURIComponent(params[key]);
    })
    .join("&");
}

export function numberWithCommas(num) {
  if (num === 0) return 0;
  if (!num) return "";

  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function getKDARatio(kills, deaths, assists) {
  if (kills === 0 && deaths === 0 && assists === 0) {
    return "0.00";
  } else if (deaths === 0) {
    return "Perfect";
  } else {
    return (Math.round(((kills + assists) / deaths) * 100) / 100).toFixed(2);
  }
}

export function getKDAColor(kda) {
  if (kda === "Perfect") {
    return "#F8B218";
  } else if (kda === "0.00") {
    return "#FFFFFF";
  }

  if (kda >= 5) {
    return "#F8B218";
  } else if (kda >= 3) {
    return "#3273FA";
  } else if (kda >= 1) {
    return "#FFFFFF";
  } else {
    return "#FF4E50";
  }
}

export function getTierColor(winrate) {
  if (!winrate || isNaN(winrate)) {
    return "";
  }

  if (winrate < 45) {
    return "#FF4E50";
  } else if (winrate < 48.5) {
    return "#FCB1B2";
  } else if (winrate < 51.5) {
    return "#FFFFFF";
  } else if (winrate < 53) {
    return "#7EA4F4";
  } else if (winrate < 55) {
    return "#3273FA";
  } else {
    return "#F8B218";
  }
}

export function getMedalIcon(placement) {
  const path = "https://static.bigbrain.gg/assets/probuildstats/medals";
  if (!placement) {
    return null;
  } else if (placement.includes("1st") || placement.indexOf("1 - ") === 0) {
    return {
      src: `${path}/medal-1st.png`,
      alt: "Gold Medal",
    };
  } else if (placement.includes("2nd") || placement.indexOf("2 - ") === 0) {
    return {
      src: `${path}/medal-2nd.png`,
      alt: "Silver Medal",
    };
  } else if (placement.includes("3rd") || placement.indexOf("3 - ") === 0) {
    return {
      src: `${path}/medal-3rd.png`,
      alt: "Bronze Medal",
    };
  } else {
    return null;
  }
}

export function filterAchievements(achievements) {
  return achievements.filter(
    (achievement) =>
      achievement.placement.includes("1st") ||
      achievement.placement.includes("2nd") ||
      achievement.placement.includes("3rd") ||
      achievement.placement.indexOf("1 - ") === 0 ||
      achievement.placement.indexOf("2 - ") === 0 ||
      achievement.placement.indexOf("3 - ") === 0,
  );
}

export function getApiRole(filterType) {
  let apiRole = null;
  switch (filterType) {
    case "top":
      apiRole = "top";
      break;
    case "jungle":
      apiRole = "jungle";
      break;
    case "middle":
      apiRole = "mid";
      break;
    case "adc":
      apiRole = "adc";
      break;
    case "support":
      apiRole = "supp";
      break;
    default:
      apiRole = "all";
  }
  return apiRole;
}

export function normalizeLeagueUrl(league) {
  switch (league) {
    case "NACL":
      return "LCS-Academy";
    case "LCS":
      return "LTAN";
    case "CBLOL":
    case "LLA":
      return "LTAS";
    default:
      return league;
  }
}
