import { ROLES, getRoleImg } from "lib/role-helpers";
import { getLeagueSeriesUrl, getRegionUrl } from "@outplayed/riot-assets";

export const ROLE_OPTIONS = [
  { value: ROLES.TOP, label: "Top", img: getRoleImg(ROLES.TOP) },
  { value: ROLES.JUNGLE, label: "Jungle", img: getRoleImg(ROLES.JUNGLE) },
  { value: ROLES.MIDDLE, label: "Middle", img: getRoleImg(ROLES.MIDDLE) },
  { value: ROLES.BOTTOM, label: "Bot", img: getRoleImg(ROLES.BOTTOM) },
  { value: ROLES.SUPPORT, label: "Support", img: getRoleImg(ROLES.SUPPORT) },
];

export const ALL_ROLE_OPTIONS = [{ value: ROLES.ALL, label: "All Roles", img: getRoleImg(ROLES.ALL) }, ...ROLE_OPTIONS];

export const MATCH_TYPE_OPTIONS = [
  { value: "suggested", label: "Suggested" },
  { value: "most_recent", label: "Most Recent" },
];
export const REGION_OPTIONS = [
  { value: "world", label: "World", img: getRegionUrl("world") },
  { value: "na1", label: "NA", img: getRegionUrl("NA") },
  { value: "euw1", label: "EUW", img: getRegionUrl("EUW") },
  { value: "kr", label: "KR", img: getRegionUrl("flags/kr"), flag: true },
  { value: "br1", label: "BR", img: getRegionUrl("flags/br"), flag: true },
  { value: "eun1", label: "EUN", img: getRegionUrl("EUN") },
  { value: "jp1", label: "JP", img: getRegionUrl("flags/jp"), flag: true },
  { value: "la1", label: "LAN", img: getRegionUrl("LAN") },
  { value: "la2", label: "LAS", img: getRegionUrl("LAS") },
  { value: "oc1", label: "OCE", img: getRegionUrl("OCE") },
  { value: "ru", label: "RU", img: getRegionUrl("flags/ru"), flag: true },
  { value: "tr1", label: "TR", img: getRegionUrl("flags/tr"), flag: true },
  { value: "ph2", label: "PH", img: getRegionUrl("PH") },
  { value: "sg2", label: "SG", img: getRegionUrl("SG") },
  { value: "th2", label: "TH", img: getRegionUrl("TH") },
  { value: "tw2", label: "TW", img: getRegionUrl("TW") },
  { value: "vn2", label: "VN", img: getRegionUrl("VN") },
];
export const LEAGUE_OPTIONS = [
  {
    value: "all",
    label: "All Regions",
    img: "https://static.bigbrain.gg/assets/lol/league_series/NEWWORLD.png",
  },
  { value: "ltan", label: "LTAN", img: getLeagueSeriesUrl("LTA-North") },
  { value: "lec", label: "EU", img: getLeagueSeriesUrl("LEC") },
  { value: "lck", label: "KR", img: getLeagueSeriesUrl("NEWKR") },
  { value: "ltas", label: "LTAS", img: getLeagueSeriesUrl("LTA-South") },
  { value: "lpl", label: "CN", img: getLeagueSeriesUrl("LPL") },
  // { value: "cblol", label: "BR", img: getLeagueSeriesUrl("NEWBR") },
  { value: "tcl", label: "TR", img: getLeagueSeriesUrl("TCL") },
  // { value: "lms", label: "TW", img: getLeagueSeriesUrl("LMS") },
  // { value: "lcl", label: "CIS", img: getLeagueSeriesUrl("LCL") },
  // { value: "lla", label: "LAT", img: getLeagueSeriesUrl("LLA") },
  // { value: "lst", label: "SEA", img: getLeagueSeriesUrl("LST") },
  // { value: "opl", label: "OCE", img: getLeagueSeriesUrl("OPL") },
  // { value: "vcs", label: "VN", img: getLeagueSeriesUrl("VCS") },
  { value: "lcp", label: "LCP", img: getLeagueSeriesUrl("LCP") },
  // { value: "lla", label: "LAT", img: getLeagueSeriesUrl("LLA") },
  { value: "emea", label: "EMEA", img: getLeagueSeriesUrl("EMEA") },
  { value: "nlc", label: "NLC", img: getLeagueSeriesUrl("NLC") },
  // { value: "ljl", label: "JP", img: getLeagueSeriesUrl("LJL") },
  // { value: "lcl", label: "RU", img: getLeagueSeriesUrl("LCL") },
  // { value: "lco", label: "OCE", img: getLeagueSeriesUrl("LCO") },
];

export const MATCH_OUTCOME_OPTIONS = [
  { value: "all", label: "All Matches" },
  { value: "victory_only", label: "Victory Only" },
];
export const IS_WORLD_OPTIONS = [
  { value: false, label: "All Matches" },
  { value: true, label: "Worlds Only" },
];

export const IS_OTP_OPTIONS = [
  { value: false, label: "No Otp" },
  { value: true, label: "With Otp" },
];

export const CHAMPION_PAGE_OPTIONS = {
  role: ALL_ROLE_OPTIONS,
  // matchType: MATCH_TYPE_OPTIONS,
  league: LEAGUE_OPTIONS,
  isWorlds: IS_WORLD_OPTIONS,
  isOtp: IS_OTP_OPTIONS,
  matchOutcome: MATCH_OUTCOME_OPTIONS,
};

export const CHAMPION_PAGE_DEFAULT = {
  role: ALL_ROLE_OPTIONS[0].value, // all
  // matchType: MATCH_TYPE_OPTIONS[0].value, // suggested
  league: LEAGUE_OPTIONS[0].value, // world
  // matchOutcome: MATCH_OUTCOME_OPTIONS[0].value, // all
  isWorlds: IS_WORLD_OPTIONS[0].value,
  isOtp: IS_OTP_OPTIONS[0].value,
  matchup: "all",
};

export const PRO_PAGE_OPTIONS = {
  role: ALL_ROLE_OPTIONS,
  //matchOutcome: MATCH_OUTCOME_OPTIONS
};

export const PRO_PAGE_DEFAULT = {
  role: ALL_ROLE_OPTIONS[0].value, // all
  //matchup: "all"
};
export const WORLD_PAGE_OPTIONS = {
  role: ALL_ROLE_OPTIONS,
  //matchOutcome: MATCH_OUTCOME_OPTIONS
};

export const WORLD_PAGE_DEFAULT = {
  role: ALL_ROLE_OPTIONS[0].value, // all
  team: "all",
  //matchup: "all"
};
