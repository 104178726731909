import { window } from "global";
import React, { useState, useEffect, useMemo } from "react";
import { Helmet } from "react-helmet-async";
import { MediaQuery } from "@outplayed/responsive";
import TopPicksTable from "./TopPicksTable";
import TopPicksChart from "./TopPicksChart";
import Role from "components/Filters/filter-types/Role";
import RoleSplit from "components/Filters/filter-types/RoleSplit";
import ErrorSection from "components/common/Error/ErrorSection";
import { useQuery } from "@apollo/client";
import { usePatches } from "lib/hooks/json-hooks";
import { ROLES, getRoleId, normalizeRole } from "lib/role-helpers";
import { ALL_ROLE_OPTIONS } from "query-params/filter-options";
import { TOP_PRO_CHAMPION_PICKS } from "graphql/queries";
import { ReactComponent as Spinner } from "svg/spinner.svg";

const maxListCount = 20;
const TopPicksPage = (props) => {
  const [currentRole, setCurrentRole] = useState(ROLES.ALL);
  const { data: patches, loading: loadingPatches, error: errorPatches } = usePatches({ ssr: true });
  const [currentPatchIndex, setCurrentPatchIndex] = useState(0);
  const [currentPatch, setCurrentPatch] = useState((patches && patches[0].id.replace("_", ".")) || "");
  const {
    data: topPicks,
    loading: loadingTopPicks,
    error: errorTopPicks,
  } = useQuery(TOP_PRO_CHAMPION_PICKS, {
    variables: { version: patches && patches[currentPatchIndex].id },
    ssr: false,
  });

  useEffect(() => {
    if (!loadingTopPicks && !errorTopPicks && !topPicks.getTopProChampionPicks.length && patches) {
      console.log("fetch didn't work. retry");
      const nextPatchIndex = Math.min(currentPatchIndex + 1, patches.length - 1);
      if (nextPatchIndex !== currentPatchIndex) {
        setCurrentPatch(patches[nextPatchIndex].id.replace("_", ".") || "");
        setCurrentPatchIndex(nextPatchIndex);
      }
    }
  }, [topPicks, loadingTopPicks, errorTopPicks]);

  useEffect(() => {
    window && window.scrollTo(0, 0);
  }, []);

  const onFilterChange = (key, option) => {
    setCurrentRole(option.value);
  };

  const { getTopProChampionPicks } = topPicks || {};
  const filteredData = useMemo(() => {
    const dataArr = (getTopProChampionPicks || [])
      .filter((champion) => {
        return currentRole === ROLES.ALL || normalizeRole(champion.role) === normalizeRole(currentRole);
      })
      .slice(0, maxListCount);

    const firstEntry = dataArr[0] || {};
    const min = { pickRate: firstEntry.pickRate || 1, winRate: firstEntry.winRate || 1 };
    const max = { pickRate: firstEntry.pickRate || 0, winRate: firstEntry.winRate || 0 };
    for (const champion of dataArr) {
      if (champion.pickRate > max.pickRate) {
        max.pickRate = champion.pickRate;
      }
      if (champion.pickRate < min.pickRate) {
        min.pickRate = champion.pickRate;
      }
      if (champion.winRate > max.winRate) {
        max.winRate = champion.winRate;
      }
      if (champion.winRate < min.winRate) {
        min.winRate = champion.winRate;
      }
    }

    return { min, max, data: dataArr };
  }, [topPicks, currentRole]);

  const dupeChampions = useMemo(() => {
    if (currentRole !== ROLES.ALL) {
      return [];
    }
    const dupeSet = new Set();
    filteredData.data.forEach((champion, index) => {
      const subArray = filteredData.data.slice(index + 1);
      const foundDupe = subArray.find((el) => el.championId === champion.championId);
      if (foundDupe) {
        dupeSet.add(champion.championId);
      }
    });
    return [...dupeSet];
  }, [filteredData, currentRole]);

  return (
    <div className="content-side-padding common-page top-picks-page">
      <Helmet>
        <title>Top 20 Probuilds Champion Picks Every Role, Patch {currentPatch}</title>
        <meta
          name="description"
          content={`The 20 best picks in every role for pro solo queue in all regions, sorted by pick rate and win rate. See what the pros are playing in Patch ${currentPatch}.`}
        />
      </Helmet>
      <div className="common-page_header top-picks-page_header">
        <h1>What are the pros playing on {currentPatch}?</h1>
        <h5>
          <span>This map shows the top 20 champions the pros played most on Patch {currentPatch}.&nbsp;</span>
          <span>
            To see how this breaks down by role, use the position filter. To sort on the left by win rate, click the "Win Rate"
            column.
          </span>
        </h5>
      </div>
      <div className="common-page_filters top-picks-page_filters">
        <RoleSplit value={currentRole} options={ALL_ROLE_OPTIONS} onFilterChange={onFilterChange} />
      </div>
      <div className="common-page_content top-picks-page_content">
        {!loadingTopPicks && errorTopPicks && <ErrorSection />}
        {loadingTopPicks ? (
          <Spinner className="spinner" />
        ) : (
          <>
            <TopPicksTable topPicks={filteredData} />
            <MediaQuery min="MOBILE_LARGE" max="DESKTOP_LARGE">
              <TopPicksChart topPicks={filteredData} dupeChampions={dupeChampions} />
            </MediaQuery>
          </>
        )}
      </div>
    </div>
  );
};

export default TopPicksPage;
