import React, { useState, useEffect, useRef, useMemo } from "react";
import MatchCard from "components/common/MatchCard/MatchCard";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import { ProMatchSummary } from "graphql/queries";
import AdBoxWrapper from "components/common/Ads/AdBoxWrapper";
import Role from "components/Filters/filter-types/Role";
import RoleSplit from "components/Filters/filter-types/RoleSplit";
import { MediaQuery } from "@outplayed/responsive";
import { useMatches } from "lib/hooks/api-hooks";
import Region from "components/Filters/filter-types/Region";
import League from "components/Filters/filter-types/League";
import Team from "components/Filters/filter-types/Team";
import { ROLES, getRoleId } from "lib/role-helpers";
import { ALL_ROLE_OPTIONS, LEAGUE_OPTIONS, REGION_OPTIONS } from "query-params/filter-options";
import { ReactComponent as Live } from "svg/LIVE.svg";
import { get } from "lodash";
import { onError } from "apollo-link-error";
import { useGlobal } from "reactn";
import { RampUnit, TagBasedAdUnits } from "@outplayed/ramp";

const GET_LATEST_PRO_MATCHES = gql`
  query LiveGameFeed(
    $numMatches: Int!,
    $proLeague: String,
    $role: String) {
    getLatestProMatches(
      numMatches: $numMatches,
      proLeague: $proLeague,
      role: $role) {
      ${ProMatchSummary}
    }
  }
`;
const GET_LATEST_WORLD_MATCHES = gql`
  query LiveGameFeed(
    $isWorlds: Boolean,
    $proLeague: String,
    $role: String) {
    getLatestWorldsMatchList(
      isWorlds: $isWorlds,
      league: $proLeague,
      role: $role) {
      ${ProMatchSummary}
    }
  }
`;

/*const GET_LATEST_PRO_MATCHES = gql`
  query LiveGameFeed(
    $numMatches: Int!) {
    getLatestProMatches(
      numMatches: $numMatches
      ) {
      ${ProMatchSummary}
    }
  }
`*/
const LiveGameFeed = (props) => {
  const [role, setRole] = useState(ROLES.ALL);
  const [league, setLeague] = useState(LEAGUE_OPTIONS[0].value);
  const [team, setTeam] = useState("all");
  const [getMatches, { data: matchPage, loading: matchPageLoading, error: matchPageError }] =
    useMatches(GET_LATEST_WORLD_MATCHES);
  const world = false;
  const [worldsPromo, setWorldsPromo] = useGlobal("worldsPromo");

  const [fullMatchList, setFullMatchList] = useState([]);
  const onFilterChange = (key, option) => {
    if (key === "role") {
      setRole(option.value);
    } else if (key === "league") {
      setLeague(option.value);
    } else if (key === "team") {
      setTeam(option.value);
    }
  };
  //console.log(matchPageError)
  //console.log(fullMatchList)
  useEffect(() => {
    getMatches({
      variables: {
        isWorlds: worldsPromo,
        role: role === ROLES.ALL ? null : role,
        proLeague: league === LEAGUE_OPTIONS[0].value ? null : league,
      },
    });
  }, [role, league]);

  useEffect(() => {
    if (matchPage) {
      setFullMatchList(Object.values(matchPage)[0]);
    }
  }, [matchPage]);

  if (fullMatchList.length === 0) {
    return (
      <div className="landing_section live-game-feed">
        <div className="header_container">
          <span className="header">
            {worldsPromo ? (
              "WORLDS TRACKER"
            ) : (
              <span>
                <MediaQuery min="MOBILE_LARGE" max="DESKTOP_LARGE">
                  <span>Probuild </span>
                </MediaQuery>
                Live Feed
              </span>
            )}{" "}
            <Live className="live-tag" />
          </span>
          <span className="filter">
            <MediaQuery at="MOBILE_SMALL">
              <div className="flex align-center">
                <Role value={role} options={ALL_ROLE_OPTIONS} onFilterChange={onFilterChange} isMobile />
                {!world && <League value={league} options={LEAGUE_OPTIONS} onFilterChange={onFilterChange} isMobile />}
                {world && <Team value={team} onFilterChange={onFilterChange} isMobile />}
              </div>
            </MediaQuery>
            <MediaQuery min="MOBILE_LARGE" max="DESKTOP_LARGE">
              <div className="flex align-center">
                <RoleSplit value={role} options={ALL_ROLE_OPTIONS} onFilterChange={onFilterChange} />
                {!world && <League value={league} options={LEAGUE_OPTIONS} onFilterChange={onFilterChange} />}
                {world && <Team value={team} onFilterChange={onFilterChange} />}
              </div>
            </MediaQuery>
          </span>
        </div>
      </div>
    );
  }

  return (
    <div className="landing_section live-game-feed">
      <div className="header_container">
        <span className="header">
          {worldsPromo ? (
            "WORLDS TRACKER"
          ) : (
            <span>
              <MediaQuery min="MOBILE_LARGE" max="DESKTOP_LARGE">
                <span>Probuild </span>
              </MediaQuery>
              Live Feed
            </span>
          )}{" "}
          <Live className="live-tag" />
        </span>
        <span className="filter">
          <MediaQuery at="MOBILE_SMALL">
            <div className="flex align-center">
              <Role value={role} options={ALL_ROLE_OPTIONS} onFilterChange={onFilterChange} isMobile />
              {!world && <League value={league} options={LEAGUE_OPTIONS} onFilterChange={onFilterChange} isMobile />}
              {world && <Team value={team} onFilterChange={onFilterChange} isMobile />}
            </div>
          </MediaQuery>
          <MediaQuery min="MOBILE_LARGE" max="DESKTOP_LARGE">
            <div className="flex align-center">
              <RoleSplit value={role} options={ALL_ROLE_OPTIONS} onFilterChange={onFilterChange} />
              {!world && <League value={league} options={LEAGUE_OPTIONS} onFilterChange={onFilterChange} />}
              {world && <Team value={team} onFilterChange={onFilterChange} />}
            </div>
          </MediaQuery>
        </span>
      </div>
      <div className="content_header">
        <div className="header_1"></div>
        <div className="header_2" style={{ paddingLeft: "52px" }}>
          Pro Player
        </div>
        <div className="header_3" style={{ textAlign: "center", paddingLeft: "2px" }}>
          Matchups
        </div>
        <div className="header_4" style={{ textAlign: "center" }}>
          KDA
        </div>
        <div className="header_5" style={{ paddingLeft: "5px" }}>
          Rune
        </div>
        <div className="header_6" style={{ textAlign: "center" }}></div>
        <div className="header_7" style={{ textAlign: "center" }}>
          Full Item Build
        </div>
        <div className="header_8"></div>
      </div>

      <div className="content">
        {(fullMatchList || [])
          .filter((m) => m.proInfo.officialName !== "")
          .slice(0, 11)
          .map((match, index) => (
            <React.Fragment key={index}>
              {index === 5 && (
                <AdBoxWrapper
                  styles={{ marginBottom: 6 }}
                  adStyles={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                  width={300}
                  height={250}
                >
                  <RampUnit
                    type={TagBasedAdUnits.MediumRectBTF}
                    id={`med-rect-btf-live-feed`}
                    pathname={location.pathname}
                    search={location.search}
                    className="flex items-center justify-center w-[300px] h-[250px]"
                  />
                </AdBoxWrapper>
                // <div className="pw-incontent-container">
                //   <div className="pw-incontent-mobile"></div>
                // </div>
              )}
              <MatchCard
                key={index}
                index={index}
                type="champion"
                match={match}
                even={index % 2 === 0}
                disableAmplitude
                isLanding
                world={worldsPromo}
              />
            </React.Fragment>
          ))}
      </div>
      {fullMatchList.length > 10 && (
        <AdBoxWrapper
          styles={{ marginBottom: 6 }}
          adStyles={{ display: "flex", alignItems: "center", justifyContent: "center" }}
          width={300}
          height={250}
        >
          <RampUnit
            type={TagBasedAdUnits.MediumRectBTF}
            id={`med-rect-btf-below-live-feed`}
            pathname={location.pathname}
            search={location.search}
            styles={{ width: 300, height: 250 }}
          />
        </AdBoxWrapper>
      )}
      {/* <div className="pw-incontent-container">
        <div className="pw-incontent-mobile"></div>
      </div> */}
    </div>
  );
};

export default LiveGameFeed;
